import { Dialog } from "primereact/dialog";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { useEffect } from "react";
import formatISODateToNormal, { compareDate } from "../utils/formatDate";

interface Props {
  visible: boolean;
  onSetHide: () => void;
  record: any;
  onSubmitEdit: (data: any) => void;
  titles: any;
  buyInfo?: any;
}

export default function EditDialog(props: Props) {
  let { register, handleSubmit, watch, setValue } = useForm();

  function loopInput(key: string, index: number) {
    return (
      <label htmlFor={key} key={index}>
        {props.titles[key]}
        {(key === "day_image" && props.record[key]) ||
        (key === "week_image" && props.record[key]) ? (
          <img
            className="zoom"
            src={`${process.env.REACT_APP_BACKEND_UPLOAD}/${props.record[key]}`}
            alt=""
          />
        ) : (
          ""
        )}
        <input
          type={
            key === "day_image" || key === "week_image"
              ? "file"
              : key === "action_date"
              ? "date"
              : "text"
          }
          defaultValue={
            key === "day_image" || key === "week_image" ? "" : props.record[key]
          }
          {...register(key)}
          disabled={
            key === "code"
            // || key === "type"
            // ||
            // key === "is_profit" ||
            // key === "profit_or_loss_percentage" ||
            // key === "profit_or_loss_amount" ||
            // key === "stop_loss_percentage" ||
            // key === "profit_and_loss_percentage" ||
            // key === "hold_date"
          }
        ></input>
      </label>
    );
  }

  //watch form value
  let buyPrice = +watch("buy_price");
  let stopLoss = +watch("stop_loss");
  let sellPrice = +watch("sell_price");
  let sellShareNumber = +watch("sell_share_number");
  let buyShareNumber = +watch("buy_share_number");
  let sellFee = +watch("sell_fee");
  let buyFee = +watch("buy_fee");
  let totalAmount = +watch("total_amount");
  let sellDate = watch("action_date");
  //change value if some form data change:
  useEffect(() => {
    if (props.record.hasOwnProperty("type")) {
      if (props.record.type === "sell" && props.buyInfo && totalAmount) {
        // let buyDate = props.buyInfo.action_date;

        let buy_total_amount = +props.buyInfo.total_amount;
        let buy_date = formatISODateToNormal(props.buyInfo.action_date);
        setValue("hold_date", compareDate(sellDate, buy_date));

        setValue("is_profit", totalAmount - -buy_total_amount >= 0 ? "Y" : "N");

        setValue(
          "profit_or_loss_percentage",
          (
            ((totalAmount - -buy_total_amount) / -buy_total_amount) *
            100
          ).toFixed(3)
        );

        setValue("profit_or_loss_amount", totalAmount - -buy_total_amount);
      }

      if (props.record.type === "buy" && buyPrice) {
        setValue("total_amount", -(buyPrice * buyShareNumber + buyFee));
      }
    }

    if (buyPrice && stopLoss) {
      setValue(
        "stop_loss_percentage",
        (((buyPrice - stopLoss) / stopLoss) * 100).toFixed(3)
      );
    }

    if (buyPrice && sellPrice) {
      setValue(
        "profit_and_loss_percentage",
        (((sellPrice - buyPrice) / sellPrice) * 100).toFixed(3)
      );
    }

    if (sellPrice && sellShareNumber && sellFee) {
      setValue("total_amount", sellPrice * sellShareNumber + sellFee);
    }
  }, [
    buyPrice,
    stopLoss,
    sellPrice,
    setValue,
    sellFee,
    sellShareNumber,
    props.record,
    props.buyInfo,
    totalAmount,
    sellDate,
    buyFee,
    buyShareNumber,
  ]);

  return (
    <Dialog
      header="Edit"
      visible={props.visible}
      style={{ width: "90vw", height: "50vh" }}
      onHide={props.onSetHide}
    >
      <Form onSubmit={handleSubmit(props.onSubmitEdit)}>
        {props.record &&
          Object.keys(props.record)
            .filter(
              (key) =>
                key !== "id" &&
                key !== "portfolio_id" &&
                key !== "buy_record_id"
            )
            .map((key, index) => loopInput(key, index))}
        <Button variant="primary" type="submit">
          提交
        </Button>
      </Form>
    </Dialog>
  );
}
