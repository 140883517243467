import { TabView, TabPanel } from "primereact/tabview";
import RecordsDataTable from "../../component/recordsDataTable";
import { useEffect, useState } from "react";
import {
  mraRecordColumnTitles,
  mraUndoRecordColumnTitles,
  translateTitlesForMRARecords,
} from "../../utils/model";
import CreateDialog from "../../component/createDialog";
export default function MraPage() {
  const [mraFinishData, setMraFinishData] = useState<any[]>([]);
  const [mraUndoData, setMraUndoData] = useState<any[]>([]);
  const [createVisible, setCreateVisible] = useState<boolean>(false);
  const [targetRecord, setTargetRecord] = useState<any>({});
  let token = localStorage.getItem("token");
  async function getFinishMraData() {
    let res = await fetch(`${process.env.REACT_APP_BACKEND}/mra/finish`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    let data: any[] = await res.json();
    setMraFinishData(data);
  }

  async function getUndoMraData() {
    let token = localStorage.getItem("token");
    let res = await fetch(`${process.env.REACT_APP_BACKEND}/mra/undo`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    let data: any[] = await res.json();
    setMraUndoData(data);
  }

  async function createMRA(data: any) {
    let formData = new FormData();

    for (let key in data) {
      if (key === "day_image" || key === "week_image") {
        formData.append(key, data[key][0]);
      } else {
        formData.append(key, data[key]);
      }
    }

    let res = await fetch(
      `${process.env.REACT_APP_BACKEND}/mra?id=${targetRecord.sell_id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    if (res.ok) {
      setCreateVisible(false);
      getUndoMraData();
    }
  }
  async function afterClickAddRecord(data: any) {
    setCreateVisible(true);
    setTargetRecord(data);
  }

  useEffect(() => {
    getFinishMraData();
    getUndoMraData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TabView>
      <TabPanel header="檢討總結"></TabPanel>
      <TabPanel header="未檢討">
        <RecordsDataTable
          records={mraUndoData}
          titles={mraUndoRecordColumnTitles}
          needZoomImg={false}
          useKey="sell_id"
          onAddRecord={(e) => afterClickAddRecord(e)}
        />
        <CreateDialog
          title={translateTitlesForMRARecords}
          visible={createVisible}
          onSetHide={() => setCreateVisible(false)}
          onSubmitCreate={(data) => createMRA(data)}
          isProfit={targetRecord?.is_profit}
          type="mra"
        />
      </TabPanel>
      <TabPanel header="已檢討">
        <RecordsDataTable
          records={mraFinishData}
          titles={mraRecordColumnTitles}
          needZoomImg={true}
          onDelete={() => {}}
          onEdit={() => {}}
          useKey="id"
        />
        {/* <EditDialog/> */}
      </TabPanel>
    </TabView>
  );
}
