import { CSVLink } from "react-csv";
import { Button } from "@mantine/core";

interface Props {
  data: any;
}

export default function CsvDemo(props: Props) {
  function mapHeader(data: any) {
    let headers: any = [];
    Object.keys(data).map((key) =>
      key === "id"
        ? ""
        : key === "user_id"
        ? ""
        : headers.push({ label: key, key: key })
    );

    return headers;
  }

  return (
    <CSVLink data={[{}]} headers={mapHeader(props.data)}>
      <Button>下載CSV demo檔</Button>
    </CSVLink>
  );
}
