import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Homepage() {
  let token = localStorage.getItem("token");
  const [portfolios, setPortfolios] = useState<any>([]);
  async function getPortfolios() {
    let res = await fetch(`${process.env.REACT_APP_BACKEND}/portfolios`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    let data = await res.json();
    setPortfolios(data);
  }

  useEffect(() => {
    getPortfolios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let navigate = useNavigate();

  /* 欠post portfolios */
  return (
    <div>
      {portfolios.length > 0 &&
        portfolios.map((portfolio: any) => (
          <div key={portfolio.id}>
            <Card border="success" style={{ width: "18rem" }}>
              <Card.Header>
                {portfolio.name}{" "}
                <button onClick={() => navigate(`/portfolio/${portfolio.id}`)}>
                  進入
                </button>
              </Card.Header>
              <Card.Body>
                <Card.Title>Success Card Title</Card.Title>
                <Card.Text>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
              </Card.Body>
            </Card>
            <br />
          </div>
        ))}
    </div>
  );
}
