import { useForm } from "react-hook-form";
import { Form, Button } from "react-bootstrap";
import { Dialog } from "primereact/dialog";
import { StockSearchInput } from "../component/stockSearchInput";
import { useEffect } from "react";
import formatISODateToNormal, {
  compareDate,
  dateAfterAdd,
} from "../utils/formatDate";

interface Props {
  title: any;
  visible: boolean;
  onSetHide: () => void;
  onSubmitCreate: (data: any) => void;
  onGetCodeValue?: (code: string) => void;
  isProfit?: string;
  buyInfo?: any;
  type: string;
}

export default function CreateDialog(props: Props) {
  let { register, handleSubmit, watch, setValue } = useForm();

  function loopInput(key: string, index: number) {
    return (
      <label htmlFor={key} key={index}>
        {key !== "code" && props.title[key]}
        {key === "type" ? (
          <select {...register(key)} defaultValue={""} required>
            <option value="" disabled>
              請選擇
            </option>
            <option value="deposit">存款</option>
            <option value="withdrawal">取款</option>
          </select>
        ) : key === "code" ? (
          <StockSearchInput
            onInputChange={(inputValue: any) =>
              props.onGetCodeValue!(inputValue)
            }
          />
        ) : (
          <input
            required
            type={
              key === "day_image" || key === "week_image"
                ? "file"
                : key === "action_date" || key === "mra_date"
                ? "date"
                : "text"
            }
            {...register(key)}
          ></input>
        )}
      </label>
    );
  }

  let buyPrice = +watch("buy_price");
  let stopLoss = +watch("stop_loss");
  let buyShareNumber = +watch("buy_share_number");
  let buyFee = +watch("buy_fee");
  let dayBuyIsCorrect = watch("day_buy_iscorrect");
  let daySellIsCorrect = watch("day_sell_iscorrect");
  let weekBuyIsCorrect = watch("week_buy_iscorrect");
  let weekSellIsCorrect = watch("week_sell_iscorrect");
  let sellPrice = +watch("sell_price");
  let sellFee = +watch("sell_fee");
  let sellShareNumber = +watch("sell_share_number");
  let totalAmount = +watch("total_amount");
  let sellDate = watch("action_date");

  useEffect(() => {
    if (buyPrice && stopLoss) {
      setValue(
        "stop_loss_percentage",
        (((buyPrice - stopLoss) / stopLoss) * 100).toFixed(3)
      );
    }

    if (buyPrice && buyShareNumber && buyFee) {
      setValue("total_amount", buyPrice * buyShareNumber + buyFee);
    }

    if (dayBuyIsCorrect && daySellIsCorrect) {
      setValue(
        "day_performance",
        props.isProfit + dayBuyIsCorrect + daySellIsCorrect
      );
    }
    if (weekBuyIsCorrect && weekSellIsCorrect) {
      setValue(
        "week_performance",
        props.isProfit + weekBuyIsCorrect + weekSellIsCorrect
      );
    }
    if (sellPrice && sellShareNumber && sellFee) {
      setValue("total_amount", sellPrice * sellShareNumber + sellFee);
    }
    if (props.buyInfo && sellDate) {
      let buy_date = formatISODateToNormal(props.buyInfo.action_date);
      setValue("hold_date", compareDate(sellDate, buy_date));
      setValue("mra_date", dateAfterAdd(sellDate, 60));
    }

    if (props.buyInfo) {
      setValue("sell_share_number", props.buyInfo.buy_share_number);
    }
    if (props.buyInfo && totalAmount) {
      let buy_total_amount = +props.buyInfo.total_amount;

      setValue("is_profit", totalAmount - -buy_total_amount >= 0 ? "Y" : "N");

      setValue(
        "profit_or_loss_percentage",
        (((totalAmount - -buy_total_amount) / -buy_total_amount) * 100).toFixed(
          3
        )
      );

      setValue("profit_or_loss_amount", totalAmount - -buy_total_amount);
    }
  }, [
    buyPrice,
    buyFee,
    buyShareNumber,
    setValue,
    stopLoss,
    dayBuyIsCorrect,
    daySellIsCorrect,
    weekBuyIsCorrect,
    weekSellIsCorrect,
    props.isProfit,
    sellFee,
    sellShareNumber,
    sellPrice,
    props.buyInfo,
    sellDate,
    totalAmount,
  ]);

  return (
    <Dialog
      header={
        props.title.code
          ? "新增買入"
          : props.type === "sell"
          ? "新增賣出"
          : props.type === "mra"
          ? "新增檢討"
          : "新增現金記錄"
      }
      visible={props.visible}
      style={{ width: "90vw", height: "50vh" }}
      onHide={props.onSetHide}
    >
      <Form onSubmit={handleSubmit(props.onSubmitCreate)}>
        {props.title &&
          Object.keys(props.title)
            .filter(
              (key) =>
                key !== "id" &&
                key !== "portfolio_id" &&
                key !== "buy_record_id"
            )
            .map((key, index) => loopInput(key, index))}
        <Button variant="primary" type="submit">
          提交
        </Button>
      </Form>
    </Dialog>
  );
}
