import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { token } from "../utils/authGuard";
export default function NarBar() {
  let navigate = useNavigate();
  async function logout() {
    localStorage.removeItem("token");
    navigate("/login");
  }

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="/">StockMe</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/home">主頁</Nav.Link>

            <NavDropdown title="模擬買入" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/fake_trade/summary">
                模擬交易分析一覧
              </NavDropdown.Item>
              <NavDropdown.Item href="/fake_trade/record">
                模擬買入紀錄
              </NavDropdown.Item>
              <NavDropdown.Item href="/fake_trade/new">
                新增模擬買入紀錄
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="倉位規劃" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/position_plan">
                倉位規劃
              </NavDropdown.Item>
              <NavDropdown.Item href="/position_plan/new">
                新增倉位規劃
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link href="/mra">交易檢討</Nav.Link>
            <Nav.Link href="/train">交易訓練場</Nav.Link>
          </Nav>
          <Nav>
            {token && (
              <Button label="登出" severity="warning" onClick={logout} raised />
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
