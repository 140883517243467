import { useParams } from "react-router-dom";
import { Accordion, AccordionTab } from "primereact/accordion";
import { useEffect, useState } from "react";
import {
  translateTitleForBuyRecords,
  translateTitlesForSellRecordsDetail,
  translateTitlesForMRARecords,
} from "../../utils/model";
import { Button } from "react-bootstrap";
import EditableInput from "../../component/editableInput";
import CreateDialog from "../../component/createDialog";

export default function RecordDetailPage() {
  let { id } = useParams();
  let token = localStorage.getItem("token");
  const [createVisible, setCreateVisible] = useState<boolean>(false);
  const [record, setRecord] = useState<any>({});
  const [isEditBuyRecord, setIsEditBuyRecord] = useState(false);
  const [isEditSellRecord, setIsEditSellRecord] = useState(false);
  const [isEditMRARecord, setIsEditMRARecord] = useState(false);
  async function getAllRecordById() {
    let res = await fetch(`${process.env.REACT_APP_BACKEND}/record?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    let allData = await res.json();

    setRecord(allData);
  }
  useEffect(() => {
    getAllRecordById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleEdit(data: any) {
    let path = "";

    data.buy_price
      ? (path = "buy")
      : data.day_image
      ? (path = "mra")
      : (path = "sell");

    let formData = new FormData();
    let res;
    if (data.day_image) {
      for (let key in data) {
        if (key === "day_image" || key === "week_image") {
          formData.append(key, data[key][0]);
        } else {
          formData.append(key, data[key]);
        }
      }
      res = await fetch(
        `${process.env.REACT_APP_BACKEND}/${path}?id=${record[path].id}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );
    } else {
      res = await fetch(
        `${process.env.REACT_APP_BACKEND}/${path}?id=${record[path].id}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
    }

    if (res.ok) {
      setIsEditBuyRecord(false);
      setIsEditSellRecord(false);
      setIsEditMRARecord(false);
      getAllRecordById();
    }
  }

  async function createMRA(data: any) {
    let formData = new FormData();

    for (let key in data) {
      if (key === "day_image" || key === "week_image") {
        formData.append(key, data[key][0]);
      } else {
        formData.append(key, data[key]);
      }
    }

    let res = await fetch(
      `${process.env.REACT_APP_BACKEND}/mra?id=${record.sell.id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    if (res.ok) {
      setCreateVisible(false);
    }
  }

  return (
    <div>
      {Object.keys(record).length !== 0 && (
        <div className="card">
          <Accordion multiple activeIndex={[0]}>
            <AccordionTab header="買入">
              {isEditBuyRecord || (
                <Button onClick={() => setIsEditBuyRecord(true)}>修改</Button>
              )}

              <EditableInput
                translateTitle={translateTitleForBuyRecords}
                type="buy"
                record={record.buy}
                isEdit={isEditBuyRecord}
                onSubmitEdit={(data) => {
                  handleEdit(data);
                }}
              />
            </AccordionTab>

            <AccordionTab header="賣出">
              {isEditSellRecord || (
                <Button onClick={() => setIsEditSellRecord(true)}>修改</Button>
              )}
              {record.hasOwnProperty("mra") || isEditSellRecord || (
                <Button
                  onClick={() => {
                    setCreateVisible(true);
                  }}
                >
                  新增檢討
                </Button>
              )}

              <EditableInput
                translateTitle={translateTitlesForSellRecordsDetail}
                type="sell"
                record={record.sell}
                isEdit={isEditSellRecord}
                buyInfo={record.buy}
                onSubmitEdit={(data) => {
                  handleEdit(data);
                }}
              />
              <CreateDialog
                title={translateTitlesForMRARecords}
                visible={createVisible}
                onSetHide={() => setCreateVisible(false)}
                onSubmitCreate={(data) => createMRA(data)}
                isProfit={record.sell.is_profit}
                type="mra"
              />
            </AccordionTab>

            {record.hasOwnProperty("mra") && (
              <AccordionTab header="檢討">
                {isEditMRARecord || (
                  <Button onClick={() => setIsEditMRARecord(true)}>修改</Button>
                )}
                <EditableInput
                  translateTitle={translateTitlesForMRARecords}
                  type="mra"
                  record={record.mra}
                  isEdit={isEditMRARecord}
                  onSubmitEdit={(data) => {
                    handleEdit(data);
                  }}
                />
              </AccordionTab>
            )}
          </Accordion>
        </div>
      )}
    </div>
  );
}
