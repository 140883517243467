import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
export default function AddPositionPlanPage() {
  const { register, handleSubmit } = useForm<any>({});
  let navigate = useNavigate();
  let token = localStorage.getItem("token");
  let chiTitles = {
    name: "名稱",
    total_capital: "初始資金",
    current_capital: "現時總結餘",
    currency: "貨幣",
    full_position_percentage: "全倉比例",
    first_position_percentage: "首次建倉比例",
    second_position_percentage: "二次建倉比例",
    last_position_percentage: "三次建倉比例",
  };
  const objectKeys = Object.keys(chiTitles) as (keyof typeof chiTitles)[]; //get all the object key and push to an array

  async function submitNewPosition(data: any) {
    let res = await fetch(`${process.env.REACT_APP_BACKEND}/position`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (res.ok) {
      navigate("/position_plan");
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit(submitNewPosition)}>
        {objectKeys.map((key, index) => (
          <div key={index}>
            <label>{chiTitles[key]}:</label>

            {key === "currency" ? (
              <div>
                {" "}
                <label>
                  HKD
                  <input type="checkbox" value="HKD" {...register(key)} />
                </label>
                <label>
                  USD
                  <input type="checkbox" value="USD" {...register(key)} />
                </label>
              </div>
            ) : (
              <input type={"text"} {...register(key)} />
            )}
          </div>
        ))}
        <button type="submit">提交</button>
      </form>
    </div>
  );
}
