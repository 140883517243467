export const allRecordColumnTitles = [
  { field: "action_date", header: "日期" },
  { field: "type", header: "類型" },
  { field: "code", header: "股票" },
  { field: "total_amount", header: "資金變化" },
];

//hold and sold record titles:

export const cashRecordColumnTitles = [
  { field: "action_date", header: "日期" },
  { field: "type", header: "類型" },
  { field: "total_amount", header: "金額" },
];

export const translateTitleForBuyRecords = {
  code: "代碼",
  action_date: "買入日期",
  buy_price: "買入價",
  buy_share_number: "數量",
  buy_fee: "手續費",
  total_amount: "總費用",
  buy_reason: "買入原因",
  stop_loss: "止損價",
  stop_loss_percentage: "止損%",
  risk_amount: "曝險額",
  type: "類別",
};

export const translateTitlesForSellRecords = {
  code: "代碼",
  action_date: "賣出日期",
  sell_price: "賣出價",
  sell_share_number: "數量",
  sell_fee: "手續費",
  total_amount: "總費用",
  sell_reason: "賣出原因",
  profit_or_loss_amount: "盈虧額",
  profit_or_loss_percentage: "盈虧%",
  is_profit: "交易有盈利",
  hold_date: "持倉時間",
  type: "類別",
};

export const translateTitlesForSellRecordsDetail = {
  action_date: "賣出日期",
  sell_price: "賣出價",
  sell_share_number: "數量",
  sell_fee: "手續費",
  total_amount: "總費用",
  sell_reason: "賣出原因",
  profit_or_loss_amount: "盈虧額",
  profit_or_loss_percentage: "盈虧%",
  is_profit: "交易有盈利?",
  hold_date: "持倉時間",
  mra_date: "檢討日",
};

export const translateTitlesForCashRecords = {
  action_date: "日期",
  total_amount: "金額",
  type: "類別",
};

export const translateTitlesForMRARecords = {
  action_date: "日期",
  day_buy_iscorrect: "日線買入正確？",
  day_sell_iscorrect: "日線賣出/止損正確？",
  day_performance: "日線操作表現",
  day_comment: "日線操作檢討",
  day_image: "日線圖",
  week_buy_iscorrect: "周線買入正確？",
  week_sell_iscorrect: "周線賣出正確？",
  week_performance: "周線操作表現",
  week_comment: "周線操作檢討",
  week_image: "周線圖",
};

export const fakeTradeEditTitle = {
  buy_date: "買入日期",
  code: "代碼",
  buy_price: "買入價",
  sell_price: "賣出價",
  stop_loss: "止損價",
  stop_loss_percentage: "止損率%",
  profit_and_loss_percentage: "盈虧率%",
  day_image: "日線圖",
  day_remark: "日線備注",
  week_image: "周線圖",
  week_remark: "周線備注",
  is_sold: "類別",
};

export const mraRecordColumnTitles = [
  { field: "code", header: "股票代號" },
  { field: "name", header: "股票名稱" },
  { field: "action_date", header: "日期" },
  { field: "day_buy_iscorrect", header: "日線買入正確" },
  { field: "day_sell_iscorrect", header: "日線賣出/止損正確" },
  { field: "day_performance", header: "日線操作表現" },
  { field: "day_comment", header: "日線操作檢討" },
  { field: "day_image", header: "日線圖" },
  { field: "week_buy_iscorrect", header: "周線買入正確?" },
  { field: "week_sell_iscorrect", header: "周線賣出正確？" },
  { field: "week_performance", header: "周線操作表現" },
  { field: "week_comment", header: "周線操作檢討" },
  { field: "week_image", header: "周線圖" },
];

export const fakeTradePageTitles = [
  { field: "buy_date", header: "買入日期" },
  { field: "code", header: "代碼" },
  // { field: "buy_price", header: "買入價" },
  // { field: "sell_price", header: "賣出價" },
  // { field: "stop_loss", header: "止損價" },
  { field: "stop_loss_percentage", header: "止損率%" },
  { field: "profit_and_loss_percentage", header: "盈虧率%" },
  { field: "day_image", header: "日線圖" },
  { field: "day_remark", header: "日線備注" },
  { field: "week_image", header: "周線圖" },
  { field: "week_remark", header: "周線備注" },
  { field: "is_sold", header: "類別" },
];

export const mraUndoRecordColumnTitles = [
  { field: "mra_date", header: "檢討日" },
  { field: "buy_date", header: "買入日期" },
  { field: "buy_price", header: "買入價" },
  { field: "sell_date", header: "賣出日期" },
  { field: "sell_price", header: "賣出價" },
  { field: "is_profit", header: "交易有盈利?" },
];
