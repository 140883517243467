import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useState } from "react";
import "primeicons/primeicons.css";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { Button } from "primereact/button";
// import EditDialog from "./editDialog";

interface Props {
  records: any[];
  titles: any[];
  onDelete?: (e: Event) => void;
  onEdit?: (e: Event) => void;
  onShowDetail?: (e: Event) => void;
  onAddRecord?: (e: Event) => void;
  needZoomImg: boolean;
  useKey: string;
}

export default function RecordsDataTable(props: Props) {
  const [isZoom, setIsZoom] = useState(false);
  const toast = useRef<Toast>(null);
  const toastBC = useRef<Toast>(null);
  const clear = (isSubmit: boolean, e?: any) => {
    toastBC.current?.clear();
    isSubmit && props.onDelete!(e);
  };

  const confirm = (e: any) => {
    toastBC.current?.show({
      severity: "info",
      sticky: true,
      className: "border-none",
      content: (
        <div
          className="flex flex-column align-items-center"
          style={{ flex: "1" }}
        >
          <div className="text-center">
            <i
              className="pi pi-exclamation-triangle"
              style={{ fontSize: "3rem" }}
            ></i>
            <div className="font-bold text-xl my-3">確定刪除?</div>
          </div>
          <div
            // className="flex gap-2"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              onClick={() => clear(true, e)}
              type="button"
              label="確定"
              className="p-button-success w-6rem"
            />
            <Button
              onClick={(e: any) => clear(false)}
              type="button"
              label="取消"
              className="p-button-warning w-6rem"
            />
          </div>
        </div>
      ),
    });
  };

  const actionTemplate = (e: any) => {
    return (
      <div className="flex flex-wrap gap-2">
        {props.onEdit && (
          <i
            className="pi pi-file-edit"
            style={{ fontSize: "1.5rem", color: "green" }}
            onClick={() => {
              props.onEdit!(e);
            }}
          ></i>
        )}

        {props.onShowDetail && (
          <i
            className="pi pi-align-justify
            "
            style={{ fontSize: "1.5rem", color: "blue" }}
            onClick={() => {
              props.onShowDetail!(e);
            }}
          ></i>
        )}
        {props.onDelete && (
          <i
            className="pi pi-trash"
            style={{ fontSize: "1.5rem", color: "red" }}
            // onClick={() => {
            //   props.onDelete!(e);
            // }}
            onClick={() => confirm(e)}
          ></i>
        )}
        {props.onAddRecord && (
          <Button
            onClick={() => {
              props.onAddRecord!(e);
            }}
          >
            新增記錄
          </Button>
        )}
      </div>
    );
  };

  const imageBodyTemplate = (rowData: any, type: string) => {
    if (rowData[type]) {
      return (
        <img
          src={`${process.env.REACT_APP_BACKEND_UPLOAD}/${rowData[type]}`}
          alt=""
          width="64px"
          className={`shadow-4 pic ${isZoom ? "zoom" : ""}`}
        />
      );
    }
  };

  return (
    <div>
      {props.needZoomImg && (
        <i
          className="pi pi-window-maximize"
          style={{ fontSize: "2rem" }}
          onClick={() => setIsZoom(!isZoom)}
        ></i>
      )}

      <DataTable
        value={props.records}
        dataKey={props.useKey}
        paginator
        rows={25}
        rowsPerPageOptions={[100, 200, 300, 400]}
        tableStyle={{ minWidth: "80rem" }}
      >
        {props.titles.map((title, index) =>
          title.field === "day_image" || title.field === "week_image" ? (
            <Column
              key={index}
              body={(data) => imageBodyTemplate(data, title.field)}
              header={title.header}
              sortable
              style={{ width: "10%" }}
            ></Column>
          ) : (
            <Column
              key={index}
              field={title.field}
              header={title.header}
              sortable
              style={{ width: "10%" }}
            ></Column>
          )
        )}
        <Column body={actionTemplate} headerClassName="w-10rem" />
      </DataTable>

      <Toast ref={toast} position="center" />
      <Toast ref={toastBC} position="center" />
    </div>
  );
}
