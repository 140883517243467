import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import RecordsDataTable from "../../component/recordsDataTable";
import formatISODateToNormal from "../../utils/formatDate";
import EditDialog from "../../component/editDialog";
import CreateDialog from "../../component/createDialog";
import {
  allRecordColumnTitles,
  cashRecordColumnTitles,
  translateTitleForBuyRecords,
  translateTitlesForCashRecords,
  translateTitlesForSellRecords,
  translateTitlesForSellRecordsDetail,
} from "../../utils/model";

export default function PortfolioDetailPage() {
  let { id } = useParams();
  let navigate = useNavigate();
  let token = localStorage.getItem("token");
  const [allRecords, setAllRecords] = useState<any[]>([]);
  const [onHoldRecords, setOnHoldRecords] = useState<any[]>([]);
  const [soldRecords, setSoldRecords] = useState<any[]>([]);
  const [cashRecords, setCashRecords] = useState<any[]>([]);
  const [targetRecord, setTargetRecord] = useState<any>({});
  const [editVisible, setEditVisible] = useState<boolean>(false);
  const [createVisible, setCreateVisible] = useState<boolean>(false);
  const [title, setTitle] = useState<any>({});
  const [buyData, setBuyData] = useState<any>({});
  const [createType, setCreateType] = useState<string>("");
  const [code, setCode] = useState<string>("");

  function getInputValue(inputValue: string) {
    setCode(inputValue);
  }

  async function getAllRecordByPortfolio() {
    let res = await fetch(
      `${process.env.REACT_APP_BACKEND}/portfolio/record?portfolioId=${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    let allData: any[] = await res.json();
    let addedIndexData = allData.map((data) => {
      return {
        id: data.id,
        action_date: formatISODateToNormal(data.action_date),
        total_amount: data.total_amount,
        code: data.code,
        type: data.type,
        index: data.id + data.type,
      };
    });

    setAllRecords(addedIndexData);
  }

  async function getOnHoldRecords() {
    let res = await fetch(
      `${process.env.REACT_APP_BACKEND}/record/hold?portfolioId=${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    let data = await res.json();
    let formattedResult = data.map((record: any) => {
      return {
        ...record,
        action_date: formatISODateToNormal(record.action_date),
      };
    });
    setOnHoldRecords(formattedResult);
  }

  async function getSoldRecords() {
    let res = await fetch(
      `${process.env.REACT_APP_BACKEND}/record/sold?portfolioId=${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    let data = await res.json();
    let formattedResult = data.map((record: any) => {
      return {
        ...record,
        action_date: formatISODateToNormal(record.action_date),
      };
    });
    setSoldRecords(formattedResult);
  }

  async function getCashRecords() {
    let res = await fetch(
      `${process.env.REACT_APP_BACKEND}/record/cash?portfolioId=${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    let data = await res.json();
    let formattedResult = data.map((record: any) => {
      return {
        ...record,
        action_date: formatISODateToNormal(record.action_date),
      };
    });
    setCashRecords(formattedResult);
  }

  async function getRecordDetailById(table: string, id: number) {
    let res = await fetch(
      `${process.env.REACT_APP_BACKEND}/record/detail?table=${table}&&id=${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    let data = await res.json();
    return data;
  }

  async function afterClickedEdit(e: any) {
    setTargetRecord({});
    let table;
    if (e.type === "buy") {
      table = "buy_records";
    } else if (e.type === "sell") {
      table = "sell_records";
    } else if (e.type === "deposit" || e.type === "withdrawal") {
      table = "deposit_withdrawal_records";
    }

    if (!table) {
      return;
    }
    let data: any = await getRecordDetailById(table, e.id);

    data["action_date"] = formatISODateToNormal(data.action_date);

    setTargetRecord(data);

    if (data.type === "buy") {
      setTitle(translateTitleForBuyRecords);
    } else if (data.type === "sell") {
      setTitle(translateTitlesForSellRecords);
      let buyInfo = await getRecordDetailById(
        "buy_records",
        data.buy_record_id
      );
      setBuyData(buyInfo);
    } else if (data.type === "deposit" || data.type === "withdrawal") {
      setTitle(translateTitlesForCashRecords);
    }

    setEditVisible(true);
  }

  async function submitRecordEdit(data: any) {
    let path;
    targetRecord.type === "buy"
      ? (path = "buy")
      : targetRecord.type === "sell"
      ? (path = "sell")
      : (path = "cash");

    let res = await fetch(
      `${process.env.REACT_APP_BACKEND}/${path}?id=${targetRecord.id}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    if (res.ok) {
      hidEditModal();
      getAllRecordByPortfolio();
      getCashRecords();
    }
  }
  function hidEditModal() {
    setEditVisible(false);
    setTargetRecord({});
    setBuyData({});
  }

  function hideCreateModal() {
    setCreateVisible(false);
    setCreateType("");
  }

  async function deleteRecord(deleteData: any) {
    let path;
    deleteData.type === "buy"
      ? (path = "buy")
      : deleteData.type === "sell"
      ? (path = "sell")
      : (path = "cash");

    let res = await fetch(
      `${process.env.REACT_APP_BACKEND}/${path}?id=${deleteData.id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.ok) {
      getAllRecordByPortfolio();
      getOnHoldRecords();
      getSoldRecords();
      getCashRecords();
    }
  }

  useEffect(() => {
    getAllRecordByPortfolio();
    getOnHoldRecords();
    getSoldRecords();
    getCashRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function submitCreateRecord(data: any) {
    let res;
    if (data.buy_price) {
      data["code"] = code;
      res = await fetch(
        `${process.env.REACT_APP_BACKEND}/buy?portfolioId=${id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
    } else {
      res = await fetch(
        `${process.env.REACT_APP_BACKEND}/cash?portfolioId=${id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
    }

    if (res?.ok) {
      hideCreateModal();
      getAllRecordByPortfolio();
    }
  }

  const modifiedTranslateTitle = { ...translateTitleForBuyRecords };

  delete (modifiedTranslateTitle as any)["type"];

  function clickCreate(type: string) {
    setCreateType(type);
    setCreateVisible(true);
  }

  function clickDetail(data: any) {
    navigate(`/record/sold/${data.id}`);
  }

  async function addSellRecord(data: any) {
    let buyInfo = await getRecordDetailById("buy_records", data.id);
    setTargetRecord(buyInfo);
    setCreateType("sell");
    setCreateVisible(true);
  }

  async function submitCreateSellRecord(data: any) {
    console.log(targetRecord.id, "insert sel1:", data);

    let res = await fetch(
      `${process.env.REACT_APP_BACKEND}/sell?buyRecordId=${targetRecord.id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    if (res.ok) {
      setCreateVisible(false);
      getOnHoldRecords();
    }
  }

  return (
    <div>
      <TabView>
        <TabPanel header="所有交易記錄">
          <div>
            <Button onClick={() => clickCreate("cash")}>新增現金記錄</Button>
            <Button onClick={() => clickCreate("buy")}>新增買入</Button>
          </div>

          <RecordsDataTable
            records={allRecords}
            titles={allRecordColumnTitles}
            onDelete={(e) => deleteRecord(e)}
            onEdit={(e) => afterClickedEdit(e)}
            needZoomImg={false}
            useKey="index"
          />
          {targetRecord.type && (
            <EditDialog
              visible={editVisible}
              record={{ ...targetRecord }}
              onSetHide={() => hidEditModal()}
              onSubmitEdit={(data) => submitRecordEdit(data)}
              titles={title}
              buyInfo={buyData}
            />
          )}
          {createType && (
            <CreateDialog
              title={
                createType === "cash"
                  ? translateTitlesForCashRecords
                  : modifiedTranslateTitle
              }
              visible={createVisible}
              onSetHide={() => hideCreateModal()}
              onSubmitCreate={(data) => submitCreateRecord(data)}
              onGetCodeValue={(code) => getInputValue(code)}
              type="cash"
            />
          )}
        </TabPanel>
        <TabPanel header="持倉">
          <RecordsDataTable
            records={onHoldRecords}
            titles={allRecordColumnTitles}
            onDelete={(e) => deleteRecord(e)}
            onEdit={(e) => afterClickedEdit(e)}
            onAddRecord={(e) => {
              addSellRecord(e);
            }}
            needZoomImg={false}
            useKey="id"
          />
          {targetRecord.type && (
            <EditDialog
              visible={editVisible}
              record={targetRecord}
              onSetHide={() => setEditVisible(false)}
              onSubmitEdit={(data) => submitRecordEdit(data)}
              titles={title}
            />
          )}
          {createType && (
            <CreateDialog
              title={
                createType === "sell" ? translateTitlesForSellRecordsDetail : []
              }
              visible={createVisible}
              onSetHide={() => hideCreateModal()}
              onSubmitCreate={(data) => submitCreateSellRecord(data)}
              buyInfo={targetRecord}
              type="sell"
            />
          )}
        </TabPanel>
        <TabPanel header="已平倉">
          <RecordsDataTable
            records={soldRecords}
            titles={allRecordColumnTitles}
            onDelete={() => {}}
            onShowDetail={(data) => clickDetail(data)}
            needZoomImg={false}
            useKey="id"
          />
          {/* {targetRecord.type && (
            <EditDialog
              visible={visible}
              record={targetRecord}
              onSetHide={() => setVisible(false)}
              onSubmitEdit={(data) => submitRecordEdit(data)}
              titles={title}
            />
          )} */}
        </TabPanel>

        <TabPanel header="現金">
          <RecordsDataTable
            records={cashRecords}
            titles={cashRecordColumnTitles}
            onDelete={(e) => deleteRecord(e)}
            onEdit={(e) => afterClickedEdit(e)}
            needZoomImg={false}
            useKey="id"
          />
          {targetRecord.type && (
            <EditDialog
              visible={editVisible}
              record={targetRecord!}
              onSetHide={() => hidEditModal()}
              onSubmitEdit={(data) => submitRecordEdit(data)}
              titles={title}
            />
          )}
        </TabPanel>
      </TabView>
    </div>
  );
}
