import { useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { compareDate } from "../utils/formatDate";
interface Props {
  translateTitle: any;
  record: any;
  type: string;
  onSubmitEdit: (data: any) => void;
  isEdit: boolean;
  buyInfo?: any;
}

export default function EditableInput(props: Props) {
  let { register, handleSubmit, watch, setValue } = useForm();
  function loopRecordItems(title: any, translate: any) {
    return (
      <div key={title}>
        {props.isEdit ? (
          <div>
            {translate[title]}:
            <input
              type={
                title === "day_image" || title === "week_image"
                  ? "file"
                  : "text"
              }
              defaultValue={
                title === "action_date"
                  ? props.record["formatted_date"]
                  : title === "day_image" || title === "week_image"
                  ? ""
                  : props.record[title]
              }
              {...register(title)}
            />
          </div>
        ) : (
          <div>
            {translate[title]}:
            {title === "action_date" ? (
              props.record["formatted_date"]
            ) : title === "day_image" || title === "week_image" ? (
              <img
                src={`${process.env.REACT_APP_BACKEND_UPLOAD}/${props.record[title]}`}
                alt=""
                width="250rem"
                height="300rem"
              />
            ) : (
              props.record[title]
            )}
          </div>
        )}
      </div>
    );
  }
  //title==="day_image"||title==="week_image"?

  //watch form value
  let buyPrice = +watch("buy_price");
  let stopLoss = +watch("stop_loss");
  let sellPrice = +watch("sell_price");
  let sellShareNumber = +watch("sell_share_number");
  let buyShareNumber = +watch("buy_share_number");
  let sellFee = +watch("sell_fee");
  let buyFee = +watch("buy_fee");
  let totalAmount = +watch("total_amount");
  let sellDate = watch("action_date");
  //change value if some form data change:
  useEffect(() => {
    if (props.type === "sell" && props.buyInfo && totalAmount) {
      // let buyDate = props.buyInfo.action_date;

      let buy_total_amount = +props.buyInfo.total_amount;
      let buy_date = props.buyInfo.formatted_date;
      setValue("hold_date", compareDate(sellDate, buy_date));

      setValue("is_profit", totalAmount - -buy_total_amount >= 0 ? "Y" : "N");

      setValue(
        "profit_or_loss_percentage",
        (((totalAmount - -buy_total_amount) / -buy_total_amount) * 100).toFixed(
          3
        )
      );

      setValue("profit_or_loss_amount", totalAmount - -buy_total_amount);
    }

    if (props.type === "buy" && buyPrice) {
      setValue("total_amount", -(buyPrice * buyShareNumber + buyFee));
    }

    if (buyPrice && stopLoss) {
      setValue(
        "stop_loss_percentage",
        (((buyPrice - stopLoss) / stopLoss) * 100).toFixed(3)
      );
    }

    if (buyPrice && sellPrice) {
      setValue(
        "profit_and_loss_percentage",
        (((sellPrice - buyPrice) / sellPrice) * 100).toFixed(3)
      );
    }

    if (sellPrice && sellShareNumber && sellFee) {
      setValue("total_amount", sellPrice * sellShareNumber + sellFee);
    }
  }, [
    buyPrice,
    stopLoss,
    sellPrice,
    setValue,
    sellFee,
    sellShareNumber,
    props.type,
    props.buyInfo,
    totalAmount,
    sellDate,
    buyFee,
    buyShareNumber,
  ]);

  return (
    <div>
      <Form onSubmit={handleSubmit(props.onSubmitEdit)}>
        {Object.keys(props.translateTitle).map(
          (title) =>
            title !== "type" && loopRecordItems(title, props.translateTitle)
        )}
        {props.isEdit && <Button type="submit">提交</Button>}
      </Form>
    </div>
  );
}
