import { useState } from "react";
import {
  AutoComplete,
  AutoCompleteCompleteEvent,
} from "primereact/autocomplete";

interface Props {
  onInputChange: (value: string) => void;
}
export function StockSearchInput(props: Props) {
  const [value, setValue] = useState<string>("");
  const [items, setItems] = useState<any[]>([]);
  let token = localStorage.getItem("token");

  const getStockBySearch = async (event: AutoCompleteCompleteEvent) => {
    let res = await fetch(
      `${process.env.REACT_APP_BACKEND}/stocks/search?code=${event.query}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    let data = await res.json();
    if (data.length >= 1) {
      setItems(data.map((stock: any) => stock.code));
    } else {
      setItems(["查無此股票"]);
    }
  };
  function changeAndPassInputValue(event: any) {
    setValue(event);
    props.onInputChange(event);
  }

  return (
    <div className="search-container">
      <label>股票代號 ：</label>
      <AutoComplete
        value={value}
        suggestions={items}
        completeMethod={getStockBySearch}
        onChange={(e) => changeAndPassInputValue(e.value)}
      />
    </div>
  );
}
