import { useEffect, useState } from "react";
import "./FakeTradePage.css";
import RecordsDataTable from "../../component/recordsDataTable";
import formatISODateToNormal from "../../utils/formatDate";
import { FakeRecord } from "../../../models/models";
import EditDialog from "../../component/editDialog";
import { fakeTradeEditTitle, fakeTradePageTitles } from "../../utils/model";
import { resize } from "../../utils/resize";

export default function FakeTradePage() {
  let token = localStorage.getItem("token");

  const [records, setRecords] = useState<FakeRecord[]>([]);
  const [targetRecord, setTargetRecord] = useState<any>();
  const [visible, setVisible] = useState<boolean>(false);

  async function getRecords() {
    let res = await fetch(`${process.env.REACT_APP_BACKEND}/fakeTrades`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    let result = await res.json();

    let formattedResult = result.map((record: FakeRecord) => {
      return { ...record, buy_date: formatISODateToNormal(record.buy_date) };
    });

    setRecords(formattedResult);
  }

  useEffect(() => {
    if (token) {
      getRecords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function del(e: any) {
    let res = await fetch(
      `${process.env.REACT_APP_BACKEND}/fakeTrade?fakeTradeId=${e.id}`,
      {
        method: "Delete",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.ok) {
      alert("刪除成功");
      getRecords();
    }
  }
  function afterClickedEdit(e: any) {
    setTargetRecord(e);
    setVisible(true);
  }

  async function submitEdit(data: any) {
    console.log("submit:", data);
    let formData = new FormData();
    formData.append("buy_date", data.buy_date);
    formData.append("code", targetRecord.code);
    formData.append("stop_loss", data.stop_loss);
    formData.append("stop_loss_percentage", data.stop_loss_percentage);
    formData.append(
      "profit_and_loss_percentage",
      data.profit_and_loss_percentage
    );
    formData.append("is_sold", data.is_sold);
    formData.append("buy_price", data.buy_price);
    formData.append("sell_price", data.sell_price);
    formData.append("day_remark", data.day_remark);
    formData.append("day_remark", data.day_remark);
    formData.append("week_remark", data.week_remark);
    console.log("append form");
    for (let i = 0; i < data.day_image.length; i++) {
      let day_image: any = await resize(data.day_image[i]);
      formData.append("day_image", day_image);
    }
    for (let i = 0; i < data.week_image.length; i++) {
      let week_image: any = await resize(data.week_image[i]);
      formData.append("week_image", week_image);
    }

    let res = await fetch(
      `${process.env.REACT_APP_BACKEND}/fakeTrade?fakeTradeId=${targetRecord.id}`,
      {
        method: "PATCH",
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      }
    );
    if (res.ok) {
      //close the dialog
      setVisible(false);
      getRecords();
      //react query call the getRecord again
    }
  }

  async function exportFakeDate() {
    let res = await fetch(`${process.env.REACT_APP_BACKEND}/fakeTrade/export`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    let filename = await res.json();
    window.location.href = `${process.env.REACT_APP_BACKEND}/${filename}`;
    await new Promise((resolve) => setTimeout(resolve, 2000));

    await fetch(
      `${process.env.REACT_APP_BACKEND}/fakeTrade/export?filename=${filename}`,
      {
        method: "DELETE",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  }

  return (
    <div className="card container-fluid">
      <i
        className="pi pi-download"
        style={{ fontSize: "2rem" }}
        onClick={() => exportFakeDate()}
      ></i>
      <RecordsDataTable
        records={records}
        titles={fakeTradePageTitles}
        onDelete={(e) => del(e)}
        onEdit={(e) => afterClickedEdit(e)}
        needZoomImg={true}
        useKey="id"
      />
      {targetRecord && (
        <EditDialog
          visible={visible}
          record={targetRecord}
          onSetHide={() => setVisible(false)}
          onSubmitEdit={(data) => submitEdit(data)}
          titles={fakeTradeEditTitle}
        />
      )}
    </div>
  );
}
