import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import MonthlyChart from "../../component/monthlyChart";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Fieldset } from "primereact/fieldset";

const summaryStyle = {
  flex: {
    display: "flex",
  },
};

function changeChiTitle(key: string) {
  let chiTitle = {
    average_loss_percentage: "整體平均虧損率",
    average_profit_percentage: "整體平均盈利率",
    success_rate: "成功率",
    total_profit_percentage: "月總盈虧率",
    max_loss_percentage: "最大虧損率",
    max_profit_percentage: "最大盈利率",
    month: "月份",
    transaction_count: "交易數量",
    year: "年份",
  };
  //@ts-ignore
  return chiTitle[key];
}

export interface Data {
  year: string;
  month: string;
  average_loss_percentage: null | string;
  average_profit_percentage: string;
  success_rate: string;
  transaction_count: string;
  max_profit_percentage: string;
  max_loss_percentage: null | string;
  total_profit_percentage: string;
}
export default function FakeTradeSummary() {
  let token = localStorage.getItem("token");
  let [overallData, setOverallData] = useState<any>({});
  let [yearByUser, setyearByUser] = useState<number>(new Date().getFullYear());
  let [monthlyData, setMonthlyData] = useState<Data[]>([]);

  async function getSummary(period: string | number) {
    let res = await fetch(
      `${process.env.REACT_APP_BACKEND}/fakeTrade/summary?period=${period}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    let result = await res.json();

    setOverallData(result[0]);
  }

  async function getMonthlySummary(period: string | number) {
    let res = await fetch(
      `${process.env.REACT_APP_BACKEND}/fakeTrade/summary/month?period=${period}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    let result = await res.json();

    setMonthlyData(result);
  }

  useEffect(() => {
    getSummary("all");
    getMonthlySummary(yearByUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="card">
        <Fieldset legend="總表現">
          <Button onClick={() => getSummary("all")}>全部</Button>

          <Button onClick={() => getSummary("year")}>本年</Button>

          <Button onClick={() => getSummary("week")}>本周</Button>

          {overallData &&
            Object.keys(overallData).map((key: string, index) => (
              <div key={index} style={summaryStyle.flex}>
                <label>{changeChiTitle(key)}: </label>
                <div>{overallData[key]}%</div>
              </div>
            ))}
        </Fieldset>
      </div>
      <Fieldset legend="每月表現">
        <div>
          <Button onClick={() => getMonthlySummary("all")}>全部</Button>
          <label>
            指定年份
            <input
              type="number"
              value={yearByUser}
              onChange={(e) => setyearByUser(+e.target.value)}
            />
            <Button onClick={() => getMonthlySummary(yearByUser)}>更改</Button>
          </label>
        </div>

        <div className="card top-margin">
          <DataTable
            value={monthlyData}
            stripedRows
            tableStyle={{ maxWidth: "10rem" }}
          >
            {monthlyData.length > 0 &&
              Object.keys(monthlyData[0]).map((key) => (
                <Column
                  key={key}
                  field={key}
                  header={changeChiTitle(key)}
                ></Column>
              ))}
          </DataTable>
        </div>
        <div className="card top-margin">
          {monthlyData.length > 0 && <MonthlyChart summaryData={monthlyData} />}
        </div>
      </Fieldset>
    </div>
  );
}
