import { StockSearchInput } from "../../component/stockSearchInput";
import { useState } from "react";
import { Button, Group, Box, NumberInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DateInput } from "@mantine/dates";
import { useNavigate } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import CsvDemo from "../../component/csvDemo";
import { FileInput } from "@mantine/core";

export default function AddFakeStockRecord() {
  const [file, setFile] = useState(null);
  let navigate = useNavigate();
  const [code, setCode] = useState<string>("");
  let token = localStorage.getItem("token");
  function getInputValue(inputValue: string) {
    setCode(inputValue);
  }
  const singleForm = useForm({});

  async function submitSingleRecordForm(formValue: any) {
    formValue["code"] = code;
    let res = await fetch(`${process.env.REACT_APP_BACKEND}/fakeTrade`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },

      body: JSON.stringify(formValue),
    });
    if (res.ok) {
      navigate("/fake_trade/record");
    }
  }

  async function submitCSV() {
    if (file) {
      let formData = new FormData();
      formData.append("csv", file);
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND}/fakeTrade/csv`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },

          body: formData,
        }
      );

      if (res.ok) {
        navigate("/fake_trade/record");
      }
    }
  }

  function countPercentage(
    newPriceColumn: string,
    oldPriceColumn: string,
    changeColumn: string
  ) {
    let newPrice = singleForm.getInputProps(newPriceColumn).value;
    let oldPrice = singleForm.getInputProps(oldPriceColumn).value;
    if (newPrice && oldPrice) {
      let result = ((newPrice - oldPrice) / oldPrice) * 100;
      singleForm.setFieldValue(changeColumn, result);
    }
  }

  return (
    <div className="top-margin card">
      <TabView>
        <TabPanel header="逐條新增">
          <Box maw={300} mx="auto">
            <StockSearchInput
              onInputChange={(inputValue) => getInputValue(inputValue)}
            />
            <form
              onSubmit={singleForm.onSubmit((values) =>
                submitSingleRecordForm(values)
              )}
            >
              <DateInput
                withAsterisk
                valueFormat="YYYY-MM-DD"
                label="買入日期"
                maw={400}
                mx="auto"
                {...singleForm.getInputProps("buy_date")}
              />
              <NumberInput
                withAsterisk
                label="買入價"
                step={0.00001}
                precision={5}
                {...singleForm.getInputProps("buy_price")}
              />
              <NumberInput
                withAsterisk
                label="止損價"
                step={0.00001}
                precision={5}
                {...singleForm.getInputProps("stop_loss")}
              />
              <NumberInput
                withAsterisk
                label="止損%"
                step={0.001}
                precision={3}
                onClick={() =>
                  countPercentage(
                    "buy_price",
                    "stop_loss",
                    "stop_loss_percentage"
                  )
                }
                {...singleForm.getInputProps("stop_loss_percentage")}
              />

              <Button type="submit">Submit</Button>

              <Group position="right" mt="md"></Group>
            </form>
          </Box>
        </TabPanel>
        <TabPanel header="批量新增">
          <CsvDemo
            data={{
              buy_date: "",
              code: "",
              buy_price: "",
              stop_loss: "",
              stop_loss_percentage: "",
              sell_price: null,
              profit_and_loss_percentage: null,
              day_image: null,
              day_remark: null,
              week_image: null,
              week_remark: null,
              type: null,
            }}
          />
          <FileInput
            label="Upload a file"
            accept=".csv"
            value={file}
            onChange={(file: any) => setFile(file)}
          />
          <Button type="submit" onClick={() => submitCSV()}>
            上傳
          </Button>
        </TabPanel>
      </TabView>
    </div>
  );
}
