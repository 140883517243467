import { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { useForm } from "react-hook-form";

interface Plan {
  name: string;
  id: number;
  total_capital: number;
  current_capital: number;
  currency: string;
  full_position_percentage: number;
  first_position_percentage: number;
  second_position_percentage: number;
  last_position_percentage: number;
}

export default function PositionPlanPage() {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editTarget, setEditTarget] = useState<number>(-1);
  const { register, handleSubmit } = useForm<any>({});
  let token = localStorage.getItem("token");
  async function getPlans() {
    let res = await fetch(`${process.env.REACT_APP_BACKEND}/positions`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    let result = await res.json();
    setPlans(result);
  }

  useEffect(() => {
    getPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function editPlan(data: any) {
    let res = await fetch(
      `${process.env.REACT_APP_BACKEND}/position?positionId=${editTarget}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    if (res.ok) {
      alert("修改成功");
      setIsEdit(false);
      getPlans();
    }
  }

  async function deletePlan(id: number) {
    let res = await fetch(
      `${process.env.REACT_APP_BACKEND}/position?positionId=${id}`,
      { method: "DELETE", headers: { Authorization: `Bearer ${token}` } }
    );
    if (res.ok) {
      alert("刪除成功");
      getPlans();
    }
  }

  function editMode(id: number) {
    setIsEdit(true);
    setEditTarget(id);
  }

  return (
    <div>
      {plans.map((plan) => (
        <Card key={plan.id} border="primary" style={{ width: "18rem" }}>
          <Card.Header>
            名稱：
            {isEdit && editTarget === plan.id ? (
              <input defaultValue={plan.name} {...register("name")} />
            ) : (
              plan.name
            )}
            {!isEdit && (
              <div>
                <button onClick={() => editMode(plan.id)}>Edit</button>
                <button onClick={() => deletePlan(plan.id)}>Del</button>
              </div>
            )}
          </Card.Header>
          <Card.Body>
            <Card.Title>
              初始資金：
              {isEdit && editTarget === plan.id ? (
                <input
                  defaultValue={plan.total_capital}
                  {...register("total_capital")}
                />
              ) : (
                plan.total_capital
              )}
            </Card.Title>
            <Card.Title>
              現時總結餘：
              {isEdit && editTarget === plan.id ? (
                <input
                  defaultValue={plan.current_capital}
                  {...register("current_capital")}
                />
              ) : (
                plan.current_capital
              )}
            </Card.Title>
            <Card.Title>
              貨幣：
              {isEdit && editTarget === plan.id ? (
                <input defaultValue={plan.currency} {...register("currency")} />
              ) : (
                plan.currency
              )}
            </Card.Title>
            <Card.Text>
              全倉比例比例:{" "}
              {isEdit && editTarget === plan.id ? (
                <input
                  defaultValue={plan.full_position_percentage}
                  {...register("full_position_percentage")}
                />
              ) : (
                plan.full_position_percentage
              )}
              %
            </Card.Text>
            {!isEdit && (
              <div>
                {(plan.current_capital * plan.full_position_percentage) / 100}
              </div>
            )}
            <Card.Text>
              首次建倉比例:{" "}
              {isEdit && editTarget === plan.id ? (
                <input
                  defaultValue={plan.first_position_percentage}
                  {...register("first_position_percentage")}
                />
              ) : (
                plan.first_position_percentage
              )}
              %
            </Card.Text>
            {!isEdit && (
              <div>
                {(plan.current_capital * plan.first_position_percentage) / 100}
              </div>
            )}

            <Card.Text>
              二次建倉比例:{" "}
              {isEdit && editTarget === plan.id ? (
                <input
                  defaultValue={plan.second_position_percentage}
                  {...register("second_position_percentage")}
                />
              ) : (
                plan.second_position_percentage
              )}
              %
            </Card.Text>
            {!isEdit && (
              <div>
                {(plan.current_capital * plan.second_position_percentage) / 100}
              </div>
            )}
            <Card.Text>
              三次建倉比例:{" "}
              {isEdit && editTarget === plan.id ? (
                <input
                  defaultValue={plan.last_position_percentage}
                  {...register("last_position_percentage")}
                />
              ) : (
                plan.last_position_percentage
              )}
              %
            </Card.Text>
            {!isEdit && (
              <div>
                {(plan.current_capital * plan.last_position_percentage) / 100}
              </div>
            )}
            {isEdit && editTarget === plan.id && (
              <div>
                <button onClick={handleSubmit(editPlan)}>Save</button>
                <button onClick={() => setIsEdit(false)}>Cancel</button>
              </div>
            )}
          </Card.Body>
        </Card>
      ))}
    </div>
  );
}

// let titles = [
//   { field: "name", header: "名稱" },
//   { field: "total_capital", header: "初始資金" },
//   { field: "current_capital", header: "現時總結餘" },
//   { field: "currency", header: "貨幣" },
//   { field: "full_position_percentage", header: "全倉比例" },
//   { field: "first_position_percentage", header: "首次建倉比例" },
//   { field: "second_position_percentage", header: "二次建倉比例" },
//   { field: "last_position_percentage", header: "三次建倉比例" },
// ];
