export default function formatISODateToNormal(dateString: string): string {
  const date = new Date(dateString);
  date.setDate(date.getDate() + 1);
  const isoDate = date.toISOString();
  const formattedDate = isoDate.slice(0, 10);
  return formattedDate;
}

export function compareDate(date2: string, date1: string) {
  let newDate: Date = new Date(date2);
  let oldDate: Date = new Date(date1);

  let diff = Math.abs(newDate.getTime() - oldDate.getTime());
  let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
  return diffDays;
}

export function dateAfterAdd(date: string, range: number) {
  let calculatedDate: Date = new Date(date);
  calculatedDate.setDate(calculatedDate.getDate() + range + 1);
  return calculatedDate.toISOString().split("T")[0];
}
