import { useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";

function Login() {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  async function submitLogin() {
    let res = await fetch(`${process.env.REACT_APP_BACKEND}/login`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ email, password }),
    });
    let reply = await res.json();
    setEmail("");
    setPassword("");
    if (reply.error) {
      setErrorMessage(reply.error);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    } else {
      localStorage.setItem("token", reply.token);
      setTimeout(() => {
        navigate("/home");
      }, 1000);
    }
  }

  return (
    <div className="App login_body">
      <div className="login-container">
        <div>
          <div>
            {" "}
            <label htmlFor="email">Email : </label>
          </div>
          <div>
            {" "}
            <input
              type="text"
              name="email"
              id="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
        </div>
        <div>
          <div>
            {" "}
            <label htmlFor="password">Password : </label>
          </div>
          <div>
            {" "}
            <input
              type="password"
              name="password"
              id="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
        </div>
        <button onClick={submitLogin}>Submit</button>
        <div className="errorMessage">{errorMessage}</div>
      </div>
    </div>
  );
}

export default Login;
