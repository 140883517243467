import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Login from "./page/loginPage/Login";
//bootstrap ui
import "bootstrap/dist/css/bootstrap.min.css";
//primereact ui
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
//primereact icon
import "primeicons/primeicons.css";

import { BrowserRouter } from "react-router-dom";
import { Routes, Route, Navigate } from "react-router-dom";
import Homepage from "./page/homepage/homepage";
import FakeTradePage from "./page/fakeTradePage/FakeTradePage";
import MraPage from "./page/mraPage/MraPage";
import PositionPlanPage from "./page/positionPlanPage/PositionPlanPage";
import AddFakeStockRecord from "./page/fakeTradePage/addFakeStockRecord";
import NarBar from "./component/navbar";
import { AuthGuard } from "./utils/authGuard";
import AddPositionPlanPage from "./page/positionPlanPage/addPositionPlanPage";
import FakeTradeSummary from "./page/fakeTradePage/fakeTradeSummary";
import PortfolioDetailPage from "./page/portfolioDetailPage/portfolioDetailPage";
import RecordDetailPage from "./page/portfolioDetailPage/recordDetailPage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <NarBar />
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<AuthGuard />}>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<Homepage />} />
        <Route path="/fake_trade/record" element={<FakeTradePage />} />
        <Route path="/fake_trade/new" element={<AddFakeStockRecord />} />
        <Route path="/fake_trade/summary" element={<FakeTradeSummary />} />
        <Route path="/mra" element={<MraPage />} />
        <Route path="/position_plan" element={<PositionPlanPage />} />
        <Route path="/position_plan/new" element={<AddPositionPlanPage />} />
        <Route path="/portfolio/:id" element={<PortfolioDetailPage />} />
        <Route path="/record/sold/:id" element={<RecordDetailPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
