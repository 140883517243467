import { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { Data } from "../page/fakeTradePage/fakeTradeSummary";
import { Button } from "primereact/button";

// const data = [
//   {
//     name: "May23",
//     uv: -14.29,
//     pv: 0,
//   },
//   {
//     name: "b/c",
//     uv: -6.25,
//     pv: 1,
//   },
// ];

interface Props {
  summaryData: Data[] | [];
}

export default function MonthlyChart(props: Props) {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [data, setData] = useState<any[]>([]);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  function mapData() {
    let mappedData = props.summaryData.map((data, index) => {
      return {
        name: `${changeMonthFromNumToEng(data.month)} ${data.year}`,
        平均虧損率: Number(data.average_loss_percentage),
        平均盈利率: Number(data.average_profit_percentage),
        成功率: Number(data.success_rate),
        月總盈虧率: Number(data.total_profit_percentage),
      };
    });

    setData(mappedData);
  }
  let colors = ["#F6FA70", "#6527BE", "#0079FF", "#00DFA2", "#FF0060"];

  function changeMonthFromNumToEng(number: string) {
    let match = {
      1: "Jan",
      2: "Feb",
      3: "March",
      4: "Apr",
      5: "May",
      6: "Jun",
      7: "Jul",
      8: "Aug",
      9: "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    //@ts-ignore
    return match[number];
  }

  useEffect(() => {
    mapData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.summaryData]);

  return (
    <>
      <div>
        <Button onClick={() => setWidth((preWidth) => preWidth + 10)}>+</Button>{" "}
        <span></span>
        <Button onClick={() => setWidth((preWidth) => preWidth - 10)}>-</Button>
      </div>
      <LineChart
        width={width}
        height={height / 2}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {data.length > 0 &&
          Object.keys(data[0]).map(
            (key, index) =>
              key !== "name" && (
                <Line
                  key={index}
                  type="monotone"
                  dataKey={key}
                  stroke={colors[index]}
                  activeDot={{ r: 8 }}
                  onClick={() => console.log("clicked")}
                />
              )
          )}

        {/* <Line type="monotone" dataKey="avg_loss_percentage" stroke="#82ca9d" /> */}
      </LineChart>
    </>
  );
}
